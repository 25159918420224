@import '../../../../assets/scss/variables/bootstrap-variables';

.app-offer {
  background: $brand-primary-blue url('../images/background.jpg') no-repeat center;
  background-size: cover;
  position: absolute;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  color: $brand-primary-white;
  text-align: center;
  font-size: 2.5vh;

  &__logo {
    width: 30vw;
    height: auto;
  }

  &__apple {
    width: 50vw;
    height: auto;
  }

  &__google {
    width: 50vw;
    height: auto;
  }

  > div:first-child {
    padding-top: 12vh;
  }

  > div:last-child {
    padding: 12vh 0;
  }

  .btn-link {
    color: inherit;
    text-decoration: underline;
  }
}
