%gray-date {
  color: #151b1e;
  font-size: 12px;
  opacity: 0.5;
}

.notification-preview {
  display: flex;
  align-items: center;
  position: relative;

  &__icon {
    flex-shrink: 0;
    margin-right: 14px;
    height: 14px;
    width: 14px;
  }
}

.notification-status {
  display: block;
  width: 6px;
  height: 6px;
  background-color: inherit;
  border-radius: 50%;

  &_unread {
    background-color: #1e326e;
  }
}

.notification-details {
  &__text {
    display: flex;
    align-items: center;
  }

  &__date {
    @extend %gray-date;
    margin: 15px 15px 15px 40px;
  }
}

.notifications-list {
  tr:not(th) {
    cursor: pointer;
  }

  td {
    vertical-align: middle !important;
  }

  &__date {
    @extend %gray-date;
    font-size: 14px;
  }
}
