// Bootstrap overrides

//
// Color system
//

$white: #fff;
$gray-100: #f5f5f7;
$gray-200: #d8dce6;
$gray-300: #b0b5c5;
$gray-400: #9198ac;
$gray-500: #757d93;
$gray-600: #5b637a;
$gray-700: #444b61;
$gray-800: #2f3547;
$gray-900: #1c212e;
$black: #000 !default;

$avatar-red: #ff9393;

$blue: #20a8d8;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #ff5454;
$orange: #fabb3d;
$yellow: #ffc107 !default;
$green: #79c447;
$teal: #20c997 !default;
$cyan: #67c2ef;

// brand colors
$brand-primary-blue: #1e326e;
$brand-primary-white: #ffffff;
$brand-secondary: #eeeeee;

//TODO: get rid of those:
$brand-secondary-orange: #ff6a00;
$brand-tertiary-cyan: #009fdf;
$brand-tertiary-light-green: #6eceb2;
$brand-tertiary-brown: #aa8066;
$brand-tertiary-dark-brown: #503728;
$brand-tertiary-dark-orange: #6e321e;
$brand-tertiary-grey: #6f6f6e;
$brand-primary-blue-darken: #002366;

$colors: (
  blue: $brand-primary-blue,
  indigo: $indigo,
  purple: $purple,
  pink: $pink,
  red: $red,
  orange: $brand-secondary-orange,
  yellow: $yellow,
  green: $green,
  teal: $teal,
  cyan: $brand-tertiary-cyan,
  white: $brand-primary-white,
  gray: $brand-tertiary-grey,
  gray-dark: $gray-800,
);

$theme-colors: (
  primary: $brand-primary-blue,
  secondary: $brand-secondary,
  success: $green,
  info: $brand-tertiary-cyan,
  warning: $orange,
  danger: $red,
  light: $gray-100,
  dark: $gray-800,
  gray-100: $gray-100,
  gray-200: $gray-200,
  gray-300: $gray-300,
  gray-400: $gray-400,
  gray-500: $gray-500,
  gray-600: $gray-600,
  gray-700: $gray-700,
  gray-800: $gray-800,
  gray-900: $gray-900,
  avatar-red: $avatar-red,
);

// Borders

$border-color: $gray-200 !default;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-transitions: true;
$enable-rounded: false;

// Body
//
// Settings for the `<body>` element.

$body-bg: #f2f4f8;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-size-base: 0.875rem;
$h4-font-size: 21px;

// Breadcrumbs

$breadcrumb-padding-x: 2rem !default;
$breadcrumb-bg: transparent;
$breadcrumb-margin-bottom: 0rem;
$breadcrumb-divider: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='10'%3E%3Cpath d='M5.8409 5.38872L1.44079 9.83907C1.22865 10.0536 0.884521 10.0536 0.672382 9.83907L0.159131 9.31993C-0.052781 9.10559 -0.0530065 8.75843 0.158226 8.54363L3.64548 5.00012L0.158452 1.45637C-0.0530064 1.24157 -0.0525533 0.89441 0.159358 0.680068L0.67261 0.160929C0.884748 -0.0536418 1.22888 -0.0536417 1.44102 0.160929L5.8409 4.61151C6.05303 4.82608 6.05303 5.17415 5.8409 5.38872Z' fill='#1E326E'/%3E%3C/svg%3E");
// Cards

$card-border-color: $gray-200;
$card-cap-bg: $gray-100;

// Dropdowns

$dropdown-padding-y: 0;
$dropdown-border-color: $gray-200;
$dropdown-divider-bg: $gray-100;

$dropdown-item-padding-y: 12px;
$dropdown-item-padding-x: 8px;

$dropdown-link-color: $gray-700;
$dropdown-link-hover-color: $gray-700;
$dropdown-link-hover-bg: $gray-100;

$dropdown-link-active-color: $gray-700;
$dropdown-link-active-bg: $gray-200;
$dropdown-link-disabled-color: $gray-500;

// Buttons

$btn-secondary-border: $gray-300;

// Progress bars

$progress-bg: $gray-100;

// Tables

$table-bg-accent: $gray-100;
$table-bg-hover: $gray-100;

// Forms

$input-group-addon-bg: $gray-100;
$input-border-color: $gray-200;
$input-group-addon-border-color: $gray-200;

:export {
  brandPrimaryBlue: $brand-primary-blue;
  brandPrimaryWhite: $brand-primary-white;
  brandSecondaryOrange: $brand-secondary-orange;
  brandTertiaryCyan: $brand-tertiary-cyan;
  brandTertiaryLightGreen: $brand-tertiary-light-green;
  brandTertiaryBrown: $brand-tertiary-brown;
  brandTertiaryDarkBrown: $brand-tertiary-dark-brown;
  brandTertiaryDarkOrange: $brand-tertiary-dark-orange;
  brandTertiaryGrey: $brand-tertiary-grey;
  blue: $brand-primary-blue;
  indigo: $indigo;
  purple: $purple;
  pink: $pink;
  red: $red;
  orange: $brand-secondary-orange;
  yellow: $yellow;
  green: $green;
  teal: $teal;
  cyan: $brand-tertiary-cyan;
  white: $brand-primary-white;
  gray: $brand-tertiary-grey;
  grayDark: $gray-800;
}
