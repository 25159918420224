@import '../../../../../assets/scss/variables/bootstrap-variables';

.app-preloader {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  background: $brand-primary-blue;
}
